import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const SamarbeteEgerydsPage = () => {
	return (
		<Layout pageTitle="Egeryds">
			<p>XXX</p>

            XXX
		</Layout>
	)
}

export default SamarbeteEgerydsPage